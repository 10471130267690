<template>
<ion-page>
    <syaberu-menu/>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-item lines="none">
              <input class="ion-margin searchBox" id="searchText" v-on:keyup="searchNotification()" type="text" v-model.trim="searchText" placeholder="キーワードで検索 / 例) 夏祭り"/>
              <!-- <ion-button shape="round" size="small" @click="searchNotification()">
                <ion-icon :icon="search"/>
              </ion-button> -->
          </ion-item>
        </ion-row>
      </ion-grid>
         <ion-list v-if="isEmpty">
            <ion-item>
              <h2>検索結果はありません</h2>
            </ion-item>
          </ion-list>
          <!-- loopの単位はここ -->
          <ion-list v-else>
              <ion-item @click="detail(notification)" v-for="notification in notifications" :key="notification.lastUpdateTimestamp">
                <ion-chip slot="start" v-if="notification.priority == 0">
                  <ion-icon v-if="notification.priority == 0" :icon="informationCircleOutline"></ion-icon>
                  <ion-label>通常</ion-label>
                </ion-chip>
                <ion-chip slot="start" color="danger" v-else>
                  <ion-icon :icon="alertCircleOutline"></ion-icon>
                  <ion-label>必須</ion-label>
                </ion-chip>
                <ion-label>
                 <!-- 添付がありますよー -->
                  <h2>{{notification.title}}</h2>
                  <p>
                     {{notification.displayDate}}
                     <ion-icon v-if="notification.imageURL != null" :icon="documentAttachOutline"/>
                  </p>
                </ion-label>
              </ion-item>
          </ion-list>
    </ion-content>
</ion-page>
</template>

<script>
import { IonPage,
  IonContent,
         IonIcon,
         IonChip,
         modalController,
         IonItem,
         IonGrid,
         IonRow,
         IonLabel
          } from '@ionic/vue';
import  NotificationModal from './NotificationModal.vue'
import  SyaberuMenu from './SyaberuMenu.vue';
//読み込むアイコンを決めないといけない
import { alertCircleOutline,
informationCircleOutline,
search,
documentAttachOutline } from 'ionicons/icons';
import  getNotifications from "./helper/getNotifications.js";
export default  {
  name: 'NotificationSearch',
  components: {
    IonPage, 
    IonContent,
    IonIcon,
    IonChip,
    SyaberuMenu,
    IonItem,
    IonGrid,
    IonRow,
    IonLabel },
  data(){
    return {
      "notifications" : [],
      "searchText" : '',
      "loadedValues" : [],
    }
  },
  setup() {
    return {
      alertCircleOutline,
      informationCircleOutline,
      documentAttachOutline,
      search
    }
  },
  async mounted(){
      this.$root.startLoading();
      const user = this.$store.state.activeUser;
      this.notifications = await getNotifications(this.axios,user.tenantId,user.userId,user.guardianId,true);
      // 初期表示用
      this.loadedValues = this.notifications;
      this.$root.endLoading();
  },
  computed : {
    isEmpty(){
      return this.loadedValues == null || this.loadedValues.length == 0
    }
  },
  methods : {
    searchNotification(){
      console.log(this.searchText);
      // this.filtered = this.notifications;
      if(this.searchText != ''){
        this.filtered = [];
        for (let index = 0; index < this.notifications.length; index++) {
          const notification = this.notifications[index];
          if(notification.title.indexOf(this.searchText) > -1){
            this.filtered.push(notification);
            continue;
          }
          if(notification.message.indexOf(this.searchText) > -1){
            this.filtered.push(notification);
          }
        }
        this.notifications = this.filtered;
      }else{
        this.notifications = this.loadedValues;
      }
    },
    async detail(notification) {
      const modal = await modalController
        .create({
          component: NotificationModal,
          componentProps: {
            title: notification.title, //these values are overriden,
            message : notification.message,
            priority : notification.priority,
            originalDocId : notification.originalDocId,
            tenantId : this.$store.state.activeUser.tenantId,
            userId : this.$store.state.activeUser.userId,
            userName : this.$store.state.activeUser.userName,
            YYYYMMDD : notification.YYYYMMDD,
            docId : notification.docId,
            range : notification.range,
            targetUserIds : notification.targetUserIds,
            targetClass : notification.targetClass,
            isRead : notification.read,
            imageURL : notification.imageURL,
            isRef : true,
            createdAt: notification.createdAt
          },
        })
      this.$store.commit('setModal',modal);
      return modal.present();
    }
  }
}
</script>
<style scoped>
 .title{
   padding-left: 20px;
   font-size: 16pt;
 }
 .searchBox{
   width: 20em;
   padding: 5px;
 }
</style>
